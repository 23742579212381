import './MobileManagerNavOptions.scss';

import React from 'react';
import { useNavigate } from 'react-router-dom';

import { ASSET_CDN_URL } from '../../../../../Constants';

import WORKREX_LOGO from '../../assets/workrex-logo.svg';

import Overlay from '../../../../common/Overlay';

const propTypes = {};

function MobileManagerNavOptions({ setOpen, open }) {
  const navigate = useNavigate();

  const closeOverlay = () => setOpen(false);
  const signIn = () => {
    closeOverlay();
    navigate('/login');
  }
  return (
    <Overlay additionalClassNames={['nav-mobile-overlay']} closeOverlay={closeOverlay} img={WORKREX_LOGO} isOpen={open}>
      <section className={'mobile-navbar-options-container'}>
        <section className={'navbar-option-container'}>
          <a className={'join-waitlist-button'} target={'_blank'} rel={'noreferrer'} href={'https://calendly.com/lance-workrex/20min'}>{'Speak to us'}</a>
          <button className={'sign-in-button'} onClick={signIn}>{'Sign in'}</button>
        </section>
      </section>
    </Overlay>
  );
}

MobileManagerNavOptions.displayName = 'MobileManagerNavOptions';
MobileManagerNavOptions.propTypes = propTypes;
export default MobileManagerNavOptions;