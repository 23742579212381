import './SwapLoginSignUp.scss';

import React from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

function SwapLoginSignUp() {
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const navigateToLogin = () => navigate('/login');

  const switchText = pathname === '/login' ? 'Don\'t have an account?' : 'Already have an account?';

  const switchButton = pathname === '/login'
    ? <a className={'auth-switch-button'} target={'_blank'} rel={'noreferrer'} href={'https://calendly.com/lance-workrex/20min'}>{'Sign up'}</a>
    : <button className={'auth-switch-button'} onClick={navigateToLogin} type={'button'}>{'Log in'}</button>

  return (
    <section className={'swap-auth-container'}>
      <p className={'has-account-no-account-text'}>{switchText}</p>
      {switchButton}
    </section>
  )
}

export default SwapLoginSignUp;