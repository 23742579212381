import './WebManagerNavButtons.scss';

import { useNavigate } from 'react-router-dom';

import React from 'react';
import PropTypes from 'prop-types';

const propTypes = {};

function WebManagerNavButtons() {
  const navigate = useNavigate();
  return (
    <section className={'web-nav-buttons no-auth'}>
      <a className={'join-waitlist-button'} target={'_blank'} rel={'noreferrer'} href={'https://calendly.com/lance-workrex/20min'}>{'Speak to us'}</a>
      <button className={'sign-in-button'} onClick={() => navigate('/login')}>
        {'Sign in'}
      </button>
    </section>
  );
}

WebManagerNavButtons.displayName = 'WebManagerNavButtons';
WebManagerNavButtons.propTypes = propTypes;
export default WebManagerNavButtons;